<template>
      <div class="labElementEdit">
        <v-breadcrumbs>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
            >Home</v-breadcrumbs-item
          >
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'labElement', params: {} }">
          Lab Element
          </v-breadcrumbs-item>
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :to="{ name: 'labElementEdit', params:{id: id} }">
            Edit Lab Element
          </v-breadcrumbs-item>
        </v-breadcrumbs>

        <v-card>
          <v-card-title>Edit Lab Element</v-card-title>
          <v-card-text>
            <labElement-form :id="id"></labElement-form>
          </v-card-text>
        </v-card>
      </div>
    </template>

    <script>
    import LabElementForm from './LabElementForm.vue';

    export default {
      name: 'labElement',
      data () {
        return {
          id: null
        }
      },
      components: {
        LabElementForm
      },
      created () {
          this.id = this.$route.params.id;
          this.$emit("showParent", false);
      }
    }
    </script>
    