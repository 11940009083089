<template>
      <div class="labElementCreate">
        <v-breadcrumbs>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
            >Home</v-breadcrumbs-item
          >
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'labElement', params: {} }">
          Lab Element
          </v-breadcrumbs-item>
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :to="{ name: 'labElementCreate', params: {} }">
            Create Lab Element
          </v-breadcrumbs-item>
        </v-breadcrumbs>

        <v-card>
          <v-card-title>Create Lab Element</v-card-title>
          <v-card-text>
            <labElement-form></labElement-form>
          </v-card-text>
        </v-card>
      </div>
    </template>
    <script>
    import LabElementForm from './LabElementForm.vue';

    export default {
      name: 'labElement',
      components: {
        LabElementForm
      },
      created() {
        this.$emit("showParent", false);
      }
    }
    </script>
    